<template>
  <div>
    <LiefengContent  @backClick="backClick" :isBack='true'>
      <template v-slot:title  >授权子系统</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button
              type="primary"
              @click="inas"
          >授权
          </Button>
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="talbeColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="changeTableSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
        <LiefengModal
            title="授权"
            :value="inasStatus"
            @input="changeInasStatus"
            width='600px'
            height='600px'

          >
          <template v-slot:contentarea>
            <ul class="list">
              <li  v-for="(item,index) in inasList" :key="index">
                <Checkbox 
                  @on-change="(val) => changeCheck(item,val,e,i)" 
                  v-model="e.select"  
                  v-for="(e,i) in item" 
                  :key="i" 
                  :label="e.label"
                >{{e.value}}</Checkbox>
              </li>
            </ul>
          </template>
          <template v-slot:toolsbar>
              <Button type="primary" @click="saveInas">保存</Button>
          </template>
        </LiefengModal>
        <LiefengModal
          title="授权菜单"
          :value="menuStatus"
          @input="changeMenuStatus"
          :fullscreen="false"
          width="500px"
          height="600px"
          >
          <template v-slot:contentarea>
            <Tree 
              :data="menuTree" 
              show-checkbox
            >
            </Tree>
          </template>
          <template v-slot:toolsbar>
             <Button type="primary" style="margin-right:10px" @click="saveMenu" v-if="menuShow">保存</Button>
             <Button type="info" @click="changeMenuStatus(false)">取消</Button>
          </template>
        </LiefengModal>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {LiefengContent, LiefengTable, LiefengModal},
  data() {
    return {
      //租户id，有可能会传
      tenantId: '',
      //分页参数
      talbeColumns: [
          {
            type: "selection",
            width: 60,
            align: "center"
          },
          {
            title: "所属子平台",
            minWidth: 200,
            align: "center",
            key:'appName'
            
          },
          {
            title: "系统名称",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.parentId == 0?params.row.paraValue.appName:params.row.paraValue.systemName
              )
            }
          },
          {
            title: "系统编码",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.parentId == 0?params.row.paraValue.appCode:params.row.paraValue.systemCode
              )
            }
          },
          // {
          //   title: "域名",
          //   // key: "oemName",
          //   minWidth: 200,
          //   align: "center",
          //   render:(h,params) => {
          //       return h(
          //           'div',{},params.row.extra.IndexParam.systemIndex?params.row.extra.IndexParam.systemIndex:''
          //       )
          //   }
          // },
          {
            title: "授权开始时间",
            key: "appName",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          {
            title: "过期时间",
            key: "remark",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          
          {
            title: "状态",
            key: "status",
            minWidth: 200,
            align: "center",
             render: (h, params) => {
              return h(
                'i-Switch',
                {
                  props: {
                    size: 'large',
                    value: params.row.status == 1
                  },
                  scopedSlots: {
                    open: () => h('span', '启用'),
                    close: () => h('span', '停用')
                  },
                  on: {
                    'on-change': () => {
                      this.changeStatus(params.row)
                    }
                  }
                }
              )
            }
            // render:(h,params) =>{
            //   return h('div',{},params.row.status == 1 ?'正常':'禁用' )
            // }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.openMenuTree(params.row)
                                          
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "授权菜单"
                                ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           // this.getSonSystem( ``)
                                //           this.$router.push(`/authCloudSonSystem?systemId=${params.row.systemId}&tenantId=${this.$route.query.tenantId}&tenantSystemId=${params.row.tenantSystemId}`)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "定义子系统"
                                // ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                              this.deleteOrgSystem(params.row)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

      ],
      tableData: [],
      loading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      currentPage: 0,

      // 授权
      inasStatus:false,
      inasList:[],
      warrantList:[],
      

      // 授权菜单
      menuShow:false,
      menuStatus:false,
      menuTree:[],
      tenantSystemModuleList:[],
      orgSystemId:'',
      
    }
  },
  async created() {
    this.getList()
    
    
  },
  methods: {
    // 顶部返回
    backClick(){
      this.$router.push('/sonsystemorg')
    },
    // 选框
    tableSelect(){

    },
    // 分页
    changeTableSize(val) {
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    // 获取列表
    getList() {
      this.loading = true
      this.$get('/auth/api/auth/pc/orgSystem/selectByPage', {
        page: this.page,
        pageSize: this.pageSize,
        orgCode: this.$route.query.orgCode
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.dataList.map( e=> {
            if(e.paraValue){
              let obj = JSON.parse(e.paraValue) 
              e.paraValue = obj
              return{
                ...e,
              }
            }else{
              let obj = {}
              e.paraValue = obj
              return {
                ...e
              }
            }
          })
          this.total = res.maxCount
          console.log(this.tableData)
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 更改系统状态
    changeStatus(data){
      this.$post('/auth/api/auth/pc/orgSystem/updateStatus',{
        orgSystemId:data.orgSystemId,
        status:data.status == 1?2:1
      }).then(res => {
        if(res.code == 200){
          this.$Message.success({
            content: '操作成功',
            background: true
          })
          this.getList()
        }else {
          this.$Message.error({
            content: '操作失败',
            background: true
          })
          this.getList()
        }
        
      })
    },
    // 获取已授权系统列表
    async getWarrantList(){
      await this.$get('/auth/api/auth/pc/orgSystem/selectByOrgCode',{
        orgCode:this.$route.query.orgCode
      }).then( res => {
        if(res.code == 200){
          // this.warrantList = res.dataList
          this.warrantList = res.dataList.map( e => e.tenantSystemId)
          console.log(this.warrantList)
        }
      })
    },
    // 获取授权系统勾选列表
    async getInasList() {
      await this.$post('/auth/api/auth/pc/tenantSystem/queryByNoPage', {
        oemCode:'csf003',
      }).then(res => {
        if (res.code == 200) {
          let arr = []
          arr = res.dataList.map(e => {
            if( e.parentId == 0){
              return {
                select:false,
                systemId:e.systemId,
                label:e.tenantSystemId,
                value:e.appName,
                parentId:e.parentId
              }
            }else{
              return{
                select:false,
                systemId:e.systemId,
                label:e.tenantSystemId,
                value:JSON.parse(e.extra.IndexParam).systemName,
                parentId:e.parentId
              }
            }
          })
          // 回显勾选
          this.warrantList.map( item => {
              arr.map( e=> {
                if(item == e.label){
                  e.select = true
                }
              })
          })
          this.inasList = arr
          let obj = {}
          arr.map( e=> {
            if(!obj.hasOwnProperty([e.systemId])){
              obj[e.systemId] = [e]
            }else{
              obj[e.systemId].push(e)
            }
          })
          let list = []
          for(let item in obj){
            list.push(obj[item])
          }
          this.inasList = list
        } else {
          this.$Message.error({
            content: '获取授权失败',
            background: true
          })
          return
        }
      })
    },
     handleinasTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.label,
          expand : true,
          children: e.children,
          value:e.value,
        }
        if(this.warrantList.includes(e.value)){
          list[i].checked = true
        }else {
          list[i].checked = false
        }
        if (list[i].children) {
          this.handleinasTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },
    
    
    // 点击授权
    inas(){
      this.inasStatus = true
      this.getWarrantList()
      this.getInasList()
    },
    // 授权框状态
    changeInasStatus(val){
      this.inasStatus = val
    },
    // 授权选择
    changeCheck(item,val,e,i){
      e.select = val
    },
    // 获取数勾选值
    getsavelist(arr,list){
      list.map( e => {
        if(e.checked){
          arr.push(e.value)
        }
        if(e.children){
          this.getsavelist(arr,e.children)
        }
      })
    },
    // 保存授权系统
    saveInas(){
        for(let j =0;j<this.inasList.length;j++){
          let num = 0
          for(let i = 0;i<this.inasList[j].length;i++){
            if(this.inasList[j][i].select){
              num++
              if(num>1){
                this.$Message.warning({
                  content: '同一类型系统只能选择一个',
                  background: true
                })
                return
              }
            }
          }
        }
      console.log(this.inasList)
      
      let savelist = []
      this.inasList.map( item => {
        item.map( e=>{
          if(e.select){
            savelist.push(e.label)
          }
        })
      })
      console.log(savelist)
      this.$post('/auth/api/auth/pc/orgSystem/assignSystemToOrg',{
        tenantSystemIds:savelist.join(','),
        orgCode:this.$route.query.orgCode
      }).then(res => {
        if(res.code == 200){
          this.$Message.success({
            content: '授权成功',
            background: true
          })
          this.inasStatus = false
          
          this.getList()
          
        }else {
          this.$Message.error({
            content: '授权失败',
            background: true
          })
        }
      })
      
    },


    // 授权菜单框
    changeMenuStatus(val){
      this.menuStatus = val
    },
    // 打开授权菜单面板-获取菜单
    openMenuTree(data){
      if(data.parentId == 0) this.menuShow = false
      else this.menuShow = true
      this.menuStatus = true
      this.orgSystemId  = data.orgSystemId 
      this.getModuleByOrgSystemId(data)
      // this.getMenuTree(data.systemId)
      // this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
      //   systemId:data.systemId
      // }).then(res => {
      //   if(res.code == 200){
      //     this.menuTree = res.dataList
      //     this.handleMenuTree(this.menuTree)
      //   }
      // })
    },
    // 获取菜单
    getMenuTree(tenantSystemId){
      this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
        tenantSystemId,
      }).then(res => {
        if(res.code == 200){
          this.menuTree = res.dataList
          this.handleMenuTree(this.menuTree)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },
    handleMenuTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.name,
          expand : true,
          children: e.child,
          value:e.sysModuleId,
        }
        if(!this.menuShow){
          list[i].checked = true
        }else{
          if(this.tenantSystemModuleList.includes(e.sysModuleId)){
            list[i].checked = true
          }else {
            list[i].checked = false
          }
        }
        if (list[i].children) {
          this.handleMenuTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },

    // 保存授权菜单
    saveMenu(){
      let savelist = []
      this.getsavelist(savelist,this.menuTree)
      this.$post('/auth/api/auth/pc/orgSystem/saveOrgMenu',{
        moduleIds:savelist.join(','),
        orgSystemId:this.orgSystemId
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content: '授权成功',
            background: true
          })
          this.menuStatus = false
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },  

    // 获取已经授权的菜单
    getModuleByOrgSystemId(data){
      this.$get('/auth/api/auth/pc/orgSystem/getModuleByOrgSystemId',{
        orgSystemId:data.orgSystemId
      }).then( res => {
        if(res.code == 200){
          this.tenantSystemModuleList = res.dataList.map( e => e.moduleId)
          this.getMenuTree(data.tenantSystemId)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },


    // 删除
    deleteOrgSystem(data){
      console.log(data)
      let name = data.parentId == 0? data.paraValue.appName:data.paraValue.systemName
      this.$Modal.confirm({
        title: "删除确定",
        content: `您正在对 <span style='color:red'>${name}</span> 进行删除操作、是否继续？`,
        onOk: () => {
          this.$post('/auth/api/auth/pc/orgSystem/delete',{
                orgSystemId:data.orgSystemId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.getList();
                }else{
                    this.$Message.error({
                        content:'删除失败',
                        background:true
                    })
                    return
                }
            })
        },
      });
    }
    
  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
.ivu-checkbox-wrapper{
  width: 48%;
  &:first-child{
    width: 100%;
  }
}
.list{
  li{
    .ivu-checkbox-wrapper{
      height: 40px;
      line-height: 40px;
    }
    &:nth-child(odd){
      background-color: #F8F8F9;
    }
  }
  
}
</style>